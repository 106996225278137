.about_top_container {
  padding-top: 80px;
  padding-bottom: 100px;
  .about_top_title {
    color: #292929;
    font-family: "Cabin", sans-serif;
    text-align: center;
    font-size: 38px;
    padding-bottom: 55px;
  }
  .about_top_box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: #f4f5ff;
    max-width: 920px;
    padding: 5% 30px;
    margin: 0 auto;
    margin-bottom: 4.6%;
    border-radius: 20px;
    .box_left {
      width: 45%;
      display: flex;
      flex-direction: column;
      .box_left_top {
        .imgae_logo_abouts {
          width: 300px;
          height: 178px;
          margin-left: 75px;
        }
      }
      .box_left_bot {
        text-align: right;
        a {
          color: #337ab7;
          text-decoration: none;
          font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-size: 14px;
          line-height: 1.42857143;
        }
      }
    }
    .box_right {
      width: 55%;
      padding: 0 85px;
      text-align: left;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 1.42857143;
      color: #333;
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .about_top_container {
    .about_top_box {
      .box_right {
        padding: 0px 36px !important;
      }
      .box_left {
        .imgae_logo_abouts {
          width: 400px !important;
          height: 237px !important;
          margin-left: unset !important;
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .about_top_container {
    .about_top_box {
      width: 720px !important;
      height: 312px !important;
      .box_right {
        padding: 0px 62px !important;
      }
      .box_left {
        .imgae_logo_abouts {
          width: 335px !important;
          height: 198px !important;
          margin-left: unset !important;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .about_top_container {
    padding-top: 70px;
    padding-left: 15px;
    padding-right: 15px;
    .about_top_box {
      .box_left {
        width: 50% !important;
        .imgae_logo_abouts {
          width: 100% !important;
          height: auto !important;
          margin: unset !important;
        }
      }
      .box_right {
        width: 50%;
        padding: 0px 30px;
      }
    }
  }
}
