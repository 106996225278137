.infor_contact {
  width: 100%;
  min-height: 400px;
  .title {
    color: #292929;
    margin-bottom: 15px;
    text-align: center;
    font-size: 38px;
  }
  .content_center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
  }
  .contact-info-gr {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 25px;
    gap: 10px;
    color: #858585;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    li {
      display: flex;
      gap: 5px;
    }
  }
  .logo_tma {
    width: 173px;
    height: 56px;
  }
  a {
    color: #337ab7;
    text-decoration: none;
  }
  .iconLetter {
    color: #7eada7;
  }
  .iconPhone {
    color: #d37a93;
  }
  .iconLocation {
    color: #7a8fc7;
  }
}
@media screen and (max-width: 991px) {
  .infor_contact {
    padding-top: 80px;
  }
}
