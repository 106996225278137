@media screen and (min-width:1700px){
  .slider_home{
    min-height: 700px;
  }
}
@media screen and (min-width:1500px) and (max-width:1699px){
  .slider_home{
    min-height: 630px;
  }
}
@media screen and (min-width:1200px) and (max-width:1499px){
  .slider_home{
    min-height: 490px;
  }
}
@media screen and (min-width:992px) and (max-width:1199px){
  .slider_home{
    min-height: 400px;
  }
}
@media screen and (min-width:727px) and (max-width:991px){
  .slider_home{
    min-height: 300px;
  }
}
@media screen  and (max-width:726px){
  .slider_home{
    min-height: 150px;
  }
}
.slider_home {
  width: 100%;
  
}
.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: #fff !important;
  opacity: unset !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.swiper-pagination-bullet-active {
  background-color: #f91111 !important;
}
