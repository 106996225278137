main {
  /* - height(nav) - height(footer) */
  min-height: calc(100vh - 80px);
}
p {
  font-size: 17px;
  line-height: 28px;
  font-weight: 400;
  color: #7c8595;
  margin-bottom: 15px;
}
