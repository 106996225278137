.bg_scroll {
  background-image: linear-gradient(139deg, #19a779, #74c947) !important;
  transition: all 0.25s ease-in-out 0s;
  position: fixed !important;
}
.header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 50;
  padding: 0 40px;
  transition: background-color 0.25s ease 0s;
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 90px;
    margin: 0 auto;
    .logo_inno {
      cursor: pointer;
    }
    ul {
      display: flex;
      padding-left: 0;
      margin: unset;
      li {
        list-style: none;
      }
    }
  }
  .active {
    color: #9bf2f3 !important;
    font-weight: 700 !important;
  }

  .new_tag_a {
    font-size: 14px !important;
    line-height: 30px !important;
    text-transform: uppercase;
    padding: 10px 20px !important;
    font-weight: 700;
    text-transform: capitalize;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
  .new_tag_a:hover {
    color: #9bf2f3;
  }
  .title_nav_mobile,
  .lang_mobile {
    display: none;
  }

  //burger
  .burger,
  .menu_check {
    display: none;
  }
  .line {
    display: block;
    // height: 2.5px;
    border: 1px solid #efefef;
    width: 100%;
    border-radius: 10px;
    background: #222222;
    transition: all 0.5s ease;
  }
  .line2 {
    width: 100%;
  }
  .line3 {
    width: 100%;
  }
  .css-features-AnyConvcom__logo-nav {
    background: url("../images/AnyConv.com__css-sprite-combined -full.webp") -211px -24px;
    width: 167px;
    height: 38px;
    display: inline-block;
  }

  .nav_item {
    padding-top: 7px;
  }
  .dropdown-menu {
    display: none !important;
    flex-direction: column;
    background-color: rgba(0, 100, 0, 0.7);
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 180px;
    padding: 10px 0px;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: right;
    list-style: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    a {
      color: #9bf2f3 !important;
      text-decoration: none;
      display: block;
      padding: 10px 15px;
      font-weight: 400;
      line-height: 1.42857143;
      font-size: 17px;
    }
    a:hover {
      background-color: #fff;
    }
  }

  .dropdown-toggle {
    color: #fff !important;
    text-decoration: none;
    display: block;
    font-weight: 600;
    font-size: 15px;
    padding-top: 3px;
    padding-left: 8px;
    padding: 10px 15px;
  }

  .dropdown:hover {
    background-color: rgba(0, 100, 0, 0.7);

    a {
      color: #9bf2f3 !important;
    }
    .dropdown-menu {
      display: block !important;
    }
  }
}
@media screen and (max-width: 991px) {
  .header {
    padding-top: 20px;
    padding-bottom: 10px;
    .nav {
      justify-content: center;
      align-items: unset;
      flex-direction: column;
      text-align: left;
      padding-right: unset;
      ul {
        justify-content: flex-end;
      }
    }
    .new_tag_a {
      padding: 10px 17px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .header {
    padding: 0 20px;

    .active {
      color: #9bf2f3 !important;
    }
    .burger {
      border: 1px solid #efefef !important;
      padding: 9px 10px;
      border-radius: 4px;
      display: block;
      padding: 8px 9px;
      height: 32px;
      width: 41px;
      position: absolute;
      top: 24px;
      right: 16px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      cursor: pointer;
    }

    .nav {
      margin: unset;
      min-height: 80px;
    }

    .nav_mobile {
      background: white;
      border-top: 1px solid #fff;
      width: 100%;
      height: auto;
      position: fixed;
      top: 7%;
      left: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 100, 0, 0.7);
      display: none !important;
    }
    .new_tag_a {
      color: #ffffff;
      display: block;
      width: 100%;
    }
    .menu_check {
      display: unset;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translate(4%, -50%);
      width: 35px;
      height: 27px;
      cursor: pointer;
      opacity: 0;
      z-index: 100;
    }
    .title_nav_mobile {
      display: none;
      font-weight: 700;
      font-size: 24px;
      line-height: 32.4px;
      color: #ffffff;
    }
    .nav_show {
      // transform: translateX(-100%);
      display: block !important;
      animation-name: fadeIn;
      animation-duration: 1s;
    }
    @-webkit-keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .line1_transform {
      transform: rotateZ(45deg) translateY(150%) translateX(35%);
      width: 120%;
    }
    .line2_transform {
      opacity: 0;
    }
    .line3_transform {
      transform: rotateZ(-45deg) translateY(-150%) translateX(29%);
      width: 120%;
    }
    .line_transform {
      color: #ffffff;
      border: 1px solid #ffffff;
      background: #fff;
    }

    //active
    .nav_mobile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 40px;
    }
    .nav_links {
      display: flex;
      flex-direction: column;
    }
    .nav ul {
      width: 100%;
      gap: 0;
      flex-direction: column;
      text-align: left;
      position: relative;
    }
  }
  .nav_item {
    padding-top: unset;
  }
  .dropdown-toggle {
    padding-bottom: 25px !important;
    padding-top: 15px !important;
  }
}
@media screen and (max-width: 600px) {
  .header {
    .nav ul {
      li::after {
        width: 85%;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .header {
    .nav ul {
      li::after {
        width: 83%;
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .header {
    .nav ul {
      li::after {
        width: 80%;
      }
    }
  }
}
