.feauture_container {
  padding-top: 90px;
  padding-bottom: 100px;
  min-height: 1042px;
  .feauture_title {
    color: #292929;
    font-family: "Cabin", sans-serif;
    text-align: center;
    h2 {
      font-size: 38px;
    }
  }
  .feauture_content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 40px;
    .item_container {
      display: flex;
      flex-direction: row;
      padding-right: 15px;
      padding-bottom: 40px;
    }
    .content_left {
      display: flex;
      flex-direction: column;
      width: 33.33%;
      padding-top: 40px;
      .item_left {
        text-align: right;
        width: 80%;
        p {
          font-size: 15px;
          line-height: 26px;
        }
        .title_left {
          font-weight: 700;
          font-size: 18px;
          margin-bottom: 15px;
          text-transform: uppercase;
        }
        .content_left {
          font-size: 15px;
          line-height: 26px;
        }
      }

      .dropcaps_right {
        background-image: linear-gradient(139deg, #19a779, #74c947);
        width: 20%;
        height: 76px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 32px;
        position: relative;
        border-radius: 25px 25px 0 25px;
        margin-left: 25px;
        margin-top: 10px;
      }
    }
    .content_right {
      width: 33.33%;
      padding-top: 40px;
      padding-left: 15px;
      .item_container {
        display: flex;
        flex-direction: row;
        padding-right: 15px;
        padding-bottom: 40px;
        .item_left {
          text-align: left;
          width: 80%;
          p {
            font-size: 15px;
            line-height: 26px;
          }
          .title_left {
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 15px;
            text-transform: uppercase;
          }
          .content_left {
            font-size: 15px;
            line-height: 26px;
          }
        }
        .dropcaps_left {
          background-image: linear-gradient(139deg, #19a779, #74c947);
          width: 20%;
          height: 76px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 32px;
          position: relative;
          border-radius: 25px 25px 25px 0;
          margin-top: 10px;
          margin-right: 25px;
        }
      }
    }
    .content_image {
      padding: 0 15px;
      .image_content {
        width: 363px;
        min-width: 363px;
        height: 757px;
      }
    }
    .slideInUp {
      animation-name: pulse;
      visibility: visible;
      animation-duration: 0.15s;
      animation-iteration-count: 5;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .feauture_container {
    .dropcaps_left {
      width: 25% !important;
    }
    .dropcaps_right {
      width: 25% !important;
    }

  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .feauture_container {
    .content_image {
      .image_content {
        width: 293px !important;
      }
    }
    .dropcaps_left {
      width: 35% !important;
    }
    .dropcaps_right {
      width: 35% !important;
    }
  }
}
@media screen and (max-width: 991px) {
  .feauture_container {
    .feauture_content {
      flex-direction: column;
      .content_image {
        .image_content {
          min-width: unset !important;
         width: 100% !important;
         height: auto !important;
        }
      }
      .content_left {
        width: 100%;
        .item_left {
          width: 100% !important;
        }
      }
      .content_right {
        width: 100%;
        .item_left {
          width: 100% !important;
        }
      }
    }
  }
}
#features .dropcaps_right .css-features-AnyConvcom__user-fiendly {
  background: url("../images/AnyConv.com__css-sprite-combined -full.webp") -555px -22px;
  width: 29px;
  height: 40px;
  display: inline-block;
}
#features .dropcaps_right .css-features-AnyConvcom__iot_white {
  background: url("../images/AnyConv.com__css-sprite-combined -full.webp") -378px -22px;
  width: 45px;
  height: 40px;
  display: inline-block;
}
#features .dropcaps_right .css-features-AnyConvcom__quick-data-collect {
  background: url("../images/AnyConv.com__css-sprite-combined -full.webp") -465px -17px;
  width: 45px;
  height: 45px;
  display: inline-block;
}
#features .dropcaps_left .css-features-AnyConvcom__smart-insight {
  background: url("../images/AnyConv.com__css-sprite-combined -full.webp") -510px -22px;
  width: 45px;
  height: 40px;
  display: inline-block;
}
#features .dropcaps_left .css-features-AnyConvcom__public-api {
  background: url("../images/AnyConv.com__css-sprite-combined -full.webp") -423px -28px;
  width: 42px;
  height: 34px;
  display: inline-block;
}

#features .dropcaps_left .css-features-free {
  background: url("../images/AnyConv.com__css-sprite-combined -full.webp") -584px -24px;
  width: 38px;
  height: 38px;
  display: inline-block;
}
