.infor_home {
  width: 100%;
  min-height: 500px;
  .content_infor {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .icon_home{
    background-color: #fff;
    padding: 5px 5px;
    font-size: 27px;
    line-height: 16px;
    margin-right: 8px;
    color: #4b5dc4;
    border-radius: 50%;
  }
  .text_content {
    width: 50%;
    padding-right: 40px;
    h1 {
      line-height: 50px;
      font-weight: 700;
      color: #ffffff;
      text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);
    }
    ul {
      list-style-type: none;
      padding-left: 0rem !important;
      padding-bottom: 20px;
    }
    li {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 10px;
      letter-spacing: 1px;
      color: #fff;
      text-shadow: 1px 1px 1px rgb(0 0 0 / 37%);
      i {
        background-color: #fff;
        padding: 5px 5px;
        font-size: 16px;
        line-height: 16px;
        margin-right: 8px;
        color: #4b5dc4;
        border-radius: 50%;
      }
    }
  }
  .video_content {
    width: 50%;
  }
  .frame-border {
    background-color: #fff;
    border: 9px solid #fff;
    webkit-border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
  }
  .content_image {
    width: 100%;
    height: auto;
    background: #ffffff;
    border: 2px;
    border-radius: 25px;
    box-shadow: #f5f5f5 0 0 10px;
    border-collapse: collapse;
    padding-bottom: 15px;
  }
  .btn-green {
    font-family: "Cabin", sans-serif;
    font-size: 19px;
    line-height: 22px;
    font-weight: 600;
    color: #fff;
    background-color: #0ccec0;
    box-shadow: 0px 8px 40px 0 rgb(0 0 0 / 37%);
    text-shadow: 1px 1px 1px rgb(0 0 0 / 25%);
    letter-spacing: 1px;
    border-radius: 5px 5px;
    margin: 10px 10px 0 10px 0;
    padding: 20px 36px;
    display: inline-block;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    text-decoration: none;
  }
  .btn-green:hover {
    box-shadow: 0px 4px 10px 0 rgb(0 0 0 / 50%);
    color: #fff !important;
    background: #09b9ac;
  }
  .btn-orange {
    background-image: linear-gradient(to top, #ea9226, #f3a62e) !important;
  }
}
@media screen and (max-width: 1199px) {
  .infor_home {
    .frame-border {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 991px) {
  .infor_home {
    .content_infor {
      flex-direction: column;
    }
    .text_content {
      width: 100%;
      padding-right: 40px;
    }
    .video_content {
      width: 100%;
      padding-top: 50px;
    }
  }
}