.send_us {
  width: 100%;
  min-height: 800px;
  padding-top: 85px;
  .title {
    color: #292929;
    margin-bottom: 15px;
    text-align: center;
    font-size: 38px;
  }
  .contact_content {
    width: 100%;
    height: auto;
    display: flex;
    border: solid 1px #e7e7e7;
    padding: 30px 20px;
    border-radius: 20px;
    background-color: white;
    width: 70%;
    margin: 0 auto;
    gap: 40px;
  }
  .contact_form {
    width: 50%;
  }
  .contact_map {
    width: 50%;
  }
  .btn_send_message {
    background-color: #f0f0f0;
  }
  .btn_send_message:hover {
    background-color: #f0f0f0;
  }
  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
  }
  .form-group {
    margin-bottom: 15px;
  }
  .popup_allow {
    display: none;
  }
  .popup_allow_success {
    display: block;
    margin-top: 20px;
    background-color: #28a745;
    height: 40px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: #fff;
  }
  .popup_allow_failed {
    display: block;
    margin-top: 20px;
    background-color: #dc3545;
    height: 40px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: #fff;
  }
}
@media screen and (max-width: 991px) {
  .send_us {
    padding-top: 115px;
    .contact_content {
      flex-wrap: wrap;
    }
    .contact_form {
      width: 100%;
    }
    .contact_map {
      width: 100%;
    }
  }
}
@media screen and (max-width: 767px) {
  .send_us {
    .popup_allow_success {
      font-size: 13px;
    }
    .popup_allow_failed {
      font-size: 13px;
    }
  }
}
