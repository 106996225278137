.home {
  width: 100%;
  min-height: 800px;
  overflow: hidden;
  position: relative;
  padding-top: 95px;
  padding-bottom: 95px;

  .home_overlay {
    background: url(../../images/hero-circles2.webp) top center no-repeat;
    width: 100%;
    opacity: 0.89;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
}
.home::before {
  -webkit-transform: rotate(174deg);
  background-image: linear-gradient(139deg, #19a779, #74c947);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#990fc3", endColorstr="#7db9e8", GradientType=1 );
  border-radius: 600px 0 0 600px;
  content: "";
  height: 2735px;
  left: -300px;
  position: absolute;
  top: -660px;
  transform: rotate(174deg);
  width: 2277px;
  z-index: -100;
}
@media screen and (max-width: 991px) {
  .home {
    padding-top: 145px;
  }
}
@media screen and (max-width: 767px) {
  .home {
    padding-top: 120px;
  }
}
