.field_company {
  width: 100%;
  min-height: 200px;
  background-image: linear-gradient(139deg, #19a779, #74c947);
  padding: 90px 0 70px 0;
  .text-center {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 36px;
    color: #0076bf;
    .pink-highlight {
      color: #ea5579;
      font-size: 60px;
      vertical-align: sub;
      margin-right: 7px;
    }
  }
  .special {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .responsive-icon {
    width: 25%;
  }
  .descbox {
    background: #fff;
    padding: 20px 5px;
    font-size: 26px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .blue-box {
    border: 3px solid #10a5ec;
    color: #10a5ec;
  }
  .pink-box {
    border: 3px solid #ea5579;
    color: #ea5579;
  }
  .light-greenbox {
    border: 3px solid #36bdb1;
    color: #36bdb1;
  }
  .violet-box {
    border: 3px solid #903c96;
    color: #903c96;
  }
  .icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .css-features-iot-icon {
    background: url("../../images/AnyConv.com__css-sprite-combined -full.webp")
      0px -2px;
    width: 55px;
    height: 60px;
    display: inline-block;
  }
  .css-features-datascience-icon {
    background: url("../../images/AnyConv.com__css-sprite-combined -full.webp") -55px -2px;
    width: 55px;
    height: 60px;
    display: inline-block;
  }
  .css-features-AI-icon {
    background: url("../../images/AnyConv.com__css-sprite-combined -full.webp") -166px -0px;
    width: 45px;
    height: 62px;
    display: inline-block;
  }
  .css-features-blockchain-icon {
    background: url("../../images/AnyConv.com__css-sprite-combined -full.webp") -110px -2px;
    width: 56px;
    height: 60px;
    display: inline-block;
  }
}
@media screen and (max-width: 991px) {
  .field_company {
    .special {
      flex-wrap: wrap;
    }
    .responsive-icon {
      width: 48%;
    }
  }
}
@media screen and (max-width: 767px) {
  .field_company {
    .responsive-icon {
      width: 100%;
    }
  }
}
