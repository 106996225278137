.footer {
  position: relative;
  overflow: hidden;
  display: block;
  margin-top: 30px;
  padding: 110px 0 55px 0;
  background-color: #fff;

  .information_footer {
    position: relative;
    p {
      font-size: 16px;
      color: #eaeaea;
      position: relative;
      line-height: 28px;
      font-weight: 400;
    }
  }
  .footer_social {
    width: 100%;
    max-width: 1140px;
    display: block;
    margin: 0 auto;
    padding-top: 5px;
    text-align: center;
    padding-left: unset;
    li {
      display: inline-block;
      margin: 0;
      line-height: 100% !important;

      a {
        color: #fff;
      }
    }
    li a i {
      display: inline-block;
      margin: 0 8px;
      padding: 0;
      font-size: 26px !important;
      line-height: 26px !important;
      color: #fff;
      transition: all 0.5s ease-in-out;
      -moz-transition: all 0.25s ease-in-out;
      -webkit-transition: all 0.25s ease-in-out;
    }
  }
}
.footer:before {
  -webkit-transform: rotate(-184deg);
  background-image: linear-gradient(139deg, #19a779, #74c947);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#990fc3", endColorstr="#7db9e8", GradientType=1 );
  border-radius: 750px;
  content: "";
  height: 1250px;
  left: -360px;
  position: absolute;
  top: 40px;
  transform: rotate(-184deg) scaleX(1.1);
  width: 2250px;
}
