@media screen and (max-width: 599px) {
  .screenShots_container {
    .title_slide {
      font-size: 22px !important;
    }
    .form_iamge {
      box-shadow: none !important;
    }
    .swiper {
      padding-bottom: 70px !important;
      padding-top: unset !important;
      .swiper-slide-active {
        transform: scale(1) !important;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .screenShots_container {
    .swiper {
      .swiper-slide {
        padding: 0 30px !important;
      }
    }
  }
}
.screenShots_container {
  min-height: 900px;
  background-image: linear-gradient(139deg, #19a779, #74c947);
  padding: 90px 0 70px 0;
  .screenShots_title {
    color: #fff;
    font-size: 38px;
    text-align: center;
    font-weight: 500;
  }
  .form_slide {
    padding-top: 20px;
    .swiper {
      padding-top: 40px;
      width: 100%;
      height: 100%;
      padding-bottom: 95px;
      cursor: pointer;
      .swiper-pagination-bullet {
        opacity: 1;
        background-color: #fff !important;
        width: 12px;
        height: 12px;
        margin-left: 7px;
        margin-right: 7px;
      }
      .swiper-pagination-bullet-active {
        background-color: rgb(44, 42, 42) !important;
        opacity: 0.5 !important;
      }
    }

    .swiper-slide {
      padding-top: 30px;
    }
    .swiper-slide-active {
      transform: scale(1.1);
      box-shadow: none !important;
    }
    .form_iamge {
      border: 7px solid rgb(238, 235, 235);
      box-shadow: 10px 10px 15px rgb(0 0 0 / 30%);
      .slide_image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title_slide {
      padding-top: 10px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      color: #fff;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }
}
